@font-face {
  font-family: 'Adieu';
  font-style:  normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/Adieu-Regular.otf") format("opentype");
}

@font-face {
  font-family: 'Adieu';
  font-style:  normal;
  font-weight: 900;
  font-display: swap;
  src: url("../fonts/Adieu-Black.otf") format("opentype");
}