@import "fonts";
@import "reset";
@import "media-queries";
@import "variables";
@import "mixins";

/* Global
---------------------------------------------- */

:root {
  font-family: var(--body-font);
  font-size: var(--font-size);
  color: var(--black);
  -webkit-font-smoothing: antialiased;
}
html {
  scroll-behavior: smooth;
}
body {
  background-color: var(--white);
}
// .uniform__potty {
//   position: absolute;
//   left: -9999px;
// }
.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap: normal !important;
  font-family: var(--serif-font);
}
.center {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
}
img {
  width: 100%;
  height: auto;
  display: block;
  background: grey;
}
.text-align-center {
  text-align: center;
}
.body-font {
  font-family: var(--body-font);
  font-weight: 700;
  line-height: 111.49%;
}
.heading-font {
  font-family: var(--heading-font);
  font-weight: 400;
  line-height: 81.818%;
  text-transform: uppercase;
  &--bold {
    font-weight: 900;
    line-height: 88.5%;
  }
}
.lr-page-padding {
  padding-left: var(--lr-page-padding);
  padding-right: var(--lr-page-padding);
}
p:not(:last-of-type) {
  margin-bottom: 1em;
}
.hide-on-desktop {
  display: none;
  @include for-small-tablet-below {
    display: block;
  }
}
.hide-on-mobile {
  display: block;
  @include for-small-tablet-below {
    display: none;
  }
}

/* Lazy loading
---------------------------------------------- */

[data-lazy] {
  visibility: hidden;
}

.image-wrap {
  background-size: cover;
  min-width: 0;
  background-color: var(--white);
}

/* Fixed bottom nav
---------------------------------------------- */

.fixed-bottom-nav {
  $self: &;
  position: fixed;
  bottom: 0;
  padding: var(--lr-page-padding);
  width: 50vw;
  z-index: 10;
  &__container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.03);
    backdrop-filter: blur(17.5px);
  }
  &__link {
    font-size: var(--tiny-font-size);
    padding: calc(var(--lr-page-padding) * 0.7) var(--lr-page-padding);
    display: inline-block;
    text-transform: capitalize;
  }
  &.responsive-color {
    #{ $self }__link {
      transition: color 0.5s ease-out;
      color: var(--black);
    }
    &--light {
      #{ $self }__link {
        color: var(--white);
      }
    }
  }
}

/* Mobile menu
---------------------------------------------- */

.mobile-menu {
  $self: &;
  position: fixed;
  bottom: 40px;
  padding: var(--lr-page-padding);
  width: 100%;
  z-index: 99;
  display: none;
  opacity: 0;
  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.03);
    backdrop-filter: blur(17.5px);
    padding: calc(var(--lr-page-padding) * 2.4) 0;
  }
  &__link {
    font-size: var(--massive-font-size);
    padding: calc(var(--lr-page-padding) * 1) var(--lr-page-padding);
    display: block;
    text-transform: uppercase;
    text-align: center;
  }
  &.responsive-color {
    #{ $self }__link {
      transition: color 0.5s ease-out;
      color: var(--black);
    }
    &--light {
      #{ $self }__link {
        color: var(--white);
      }
    }
  }
}

.mobile-menu-button {
  $self: &;
  position: fixed;
  bottom: 0;
  padding: var(--lr-page-padding);
  width: 100%;
  z-index: 10;
  &__container {
    background: rgba(255, 255, 255, 0.03);
    backdrop-filter: blur(17.5px);
  }
  &__button {
    font-size: var(--tiny-font-size);
    padding: calc(var(--lr-page-padding) * 1) var(--lr-page-padding);
    display: inline-block;
    text-align: center;
    width: 100%;
  }
  &.responsive-color {
    #{ $self }__button {
      transition: color 0.5s ease-out;
      color: var(--black);
    }
    &--light {
      #{ $self }__button {
        color: var(--white);
      }
    }
  }
}

.studio-template {
  .mobile-menu-button {
    width: 50%;
  }
}

/* Mobile index button
---------------------------------------------- */

.mobile-index {
  $self: &;
  position: fixed;
  bottom: 40px;
  padding: var(--lr-page-padding);
  width: 100%;
  z-index: 99;
  display: none;
  opacity: 0;
  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.03);
    backdrop-filter: blur(17.5px);
    padding: calc(var(--lr-page-padding) * 2.4) 0;
    gap: var(--);
  }
}

.mobile-index-button {
  $self: &;
  position: fixed;
  bottom: 0;
  right: 0;
  padding: var(--lr-page-padding);
  width: 50%;
  z-index: 10;
  &__container {
    background: rgba(255, 255, 255, 0.03);
    backdrop-filter: blur(17.5px);
  }
  &__button {
    font-size: var(--tiny-font-size);
    padding: calc(var(--lr-page-padding) * 1) var(--lr-page-padding);
    display: inline-block;
    text-align: center;
    width: 100%;
    color: var(--white);
  }
}

/* Home
---------------------------------------------- */

.home-banner {
  $self: &;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100vh;
  width: 100vw;
  @include for-small-tablet-below {
    grid-template-columns: 1fr;
    height: initial;
    width: initial;
  }
  &__left {
    background-color: var(--orange);
    position: relative;
    @include for-small-tablet-below {
      height: 100vh;
      height: 100svh;
    }
  }
  &__right {
    background-color: var(--green);
    position: relative;
    @include for-small-tablet-below {
      #{ $self }__video {
        display: none;
      }
    }
  }
  &__lupine-logo {
    display: block;
    z-index: 1;
    padding: var(--lr-page-padding);
    position: sticky;
    top: 0;
    svg {
      width: 100%;
      height: auto;
      path {
        fill: var(--white);
      }
    }
  }
  &__video {
    position: absolute;
    top: 0;
    height: 100vh;
    width: 100%;
    @include for-small-tablet-below {
      height: 100svh;
    }
  }
  &__video video {
    height: 100vh;
    width: 100%;
    object-fit: cover;
    @include for-small-tablet-below {
      height: 100svh;
    }
  }
  &__video-overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.01);
    backdrop-filter: blur(400px);
    @include for-small-tablet-below {
      position: initial;
    }
  }
}

.highlight {
  background: var(--white);
  color: var(--black);
  @include for-small-tablet-below {
    background: var(--black);
    color: var(--white);
  }
  &__text {
    font-size: var(--extra-tiny-font-size);
    padding: calc(var(--lr-page-padding) * 0.6);
    width: 22vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include for-small-tablet-below {
      width: 100vw;
    }
    a {
      @include underline;
    }
  }
  .image-wrap {
    display: block;
  }
  &__image {
    width: 22vw;
    height: auto;
    aspect-ratio: 1 / 1.22;
    object-fit: cover;
    @include for-small-tablet-below {
      width: 100vw;
    }
  }
}

.manifesto {
  padding-top: calc(var(--lr-page-padding) * 1.2);
  height: 36.5vw;
  font-size: var(--massive-font-size);
  @include for-small-tablet-below {
    height: initial;
    padding-bottom: 6.5rem;
  }
  &__text {
    max-width: 78vw;
    @include for-small-tablet-below {
      max-width: 95vw;
    }
    &--thinner-width {
      max-width: 60vw;
      @include for-small-tablet-below {
        max-width: 95vw;
      }
    }
  }
}

.latest-studio {
  &__text {
    font-size: var(--extra-tiny-font-size);
    padding-bottom: calc(var(--lr-page-padding) * 0.5);
    text-align: center;
  }
  .event-details {
    padding-bottom: 2vw;
    @include for-small-tablet-below {
      padding-bottom: 1rem;
    }
  }
}

.event-card {
  &__container {
    position: relative;
    .video-hero {
      pointer-events: none;
      position: relative;
      cursor: pointer;
      .video-hero__wrapper {
        width: 100%;
        background-color: var(--black);
        video {
          object-fit: cover;
          width: 100%;
          height: 100vh;
          display: block;
          background-color: var(--cream);
          @include for-small-tablet-below {
            height: 35vh;
            height: 35svh;
          }
        }
      }
    }
  }
  &__event-details-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &__button-container {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0 var(--lr-page-padding);
    width: 100%;
    z-index: 2;
  }
  &__button {
    font-size: var(--extra-tiny-font-size);
    color: var(--white);
    padding: calc(var(--lr-page-padding) * 0.5);
    padding-top: 3rem;
    @include underline;
  }
}

.event-details {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  color: var(--white);
  padding: var(--lr-page-padding);
  padding-top: calc(var(--lr-page-padding) * 0.4);
  @include studio-title;
  &__info-wrapper {
    padding: var(--lr-page-padding) 0;
    display: block;
  }
  &__info-container {
    display: grid;
    grid-template-columns: 1.35fr 1fr 0.8fr;
    gap: 1em;
    font-size: var(--extra-small-font-size);
    @include for-small-tablet-below {
      grid-template-columns: 1fr;
      gap: 0.2em;
      font-size: 16px;
    }
  }
  &__date {
    text-align: right;
    @include for-small-tablet-below {
      text-align: left;
    }
  }
}

.latest-campaign {
  background-color: var(--orange);
  color: var(--white);
  &__heading-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: end;
    height: 40vw;
    padding: var(--lr-page-padding);
    font-size: var(--massive-font-size);
    max-width: 20vw;
    @include for-small-tablet-below {
      min-height: 200px;
    }
  }
  &__heading {
    padding-bottom: var(--lr-page-padding);
  }
  &__info-wrapper {
    height: 35vw;
    padding: var(--lr-page-padding);
    @include for-small-tablet-below {
      height: initial;
    }
  }
  &__info-container {
    display: grid;
    grid-template-columns: 0.9fr 1fr 0.2fr;
    gap: 1em;
    @include for-small-tablet-below {
      grid-template-columns: initial;
      grid-template-areas:
        "client year"
        "description description";
    }
  }
  &__client {
    font-size: var(--extra-large-font-size);
    @include for-small-tablet-below {
      grid-area: client;
      font-size: 16px;
    }
  }
  &__description {
    font-size: var(--extra-tiny-font-size);
    max-width: 55%;
    line-height: 105%;
    @include for-small-tablet-below {
      grid-area: description;
      max-width: 100%;
      margin: 5rem 0 4rem;
    }
  }
  &__year {
    text-align: right;
    font-size: var(--extra-small-font-size);
    @include for-small-tablet-below {
      grid-area: year;
      font-size: 16px;
    }
  }
}

.agency-segment {
  margin-bottom: calc(var(--lr-page-padding) * 0);
  &__sticky-content {
    height: 100vh;
    position: sticky;
    top: 0;
    @include for-small-tablet-below {
      height: initial;
      top: -300px;
    }
  }
  .clients-title {
    position: relative;
    @include for-small-tablet-below {
      height: 300px;
    }
  }
  .client-image {
    @include for-small-tablet-below {
      bottom: initial;
      right: initial;
      left: 0;
      top: 0;
      &__image {
        width: 55vw;
      }
    }
  }
  .client-images {
    position: absolute;
    @include for-small-tablet-below {
      bottom: initial;
      right: initial;
      left: 0;
      top: 0;
      margin-top: 300px;
    }
  }
  .client-list {
    margin-top: -15vw;
    @include for-small-tablet-below {
      margin-top: 0vw;
      padding-top: 140vw;
    }
  }
  .client-name {
    &:last-of-type {
      padding-bottom: calc(var(--lr-page-padding) * 30);
      @include for-small-tablet-below {
        padding-bottom: calc(var(--lr-page-padding) * 10);
      }
    }
  }
}

.higher-level {
  position: relative; // Use this if problem persists with client image showing the wrong one
  position: absolute;
  z-index: 2;
}

.latest-journal {
  background-color: var(--green);
  padding: 0 var(--lr-page-padding);
  @include for-small-tablet-below {
    padding: 0 0;
    position: relative;
  }
  .journal-title {
    @include for-small-tablet-below {
      position: absolute;
      top: 0;
      z-index: 10;
      color: var(--white);
      padding-left: var(--lr-page-padding);
      padding-right: var(--lr-page-padding);
    }
  }
  .article-card {
    @include for-small-tablet-below {
      transform: translate(0%, 0%);
      top: initial;
      bottom: 0;
      padding-bottom: initial;
    }
  }
}

.journal-title {
  font-size: 8.275vw;
  margin-left: -0.66vw;
  margin-right: -0.66vw;
  padding-top: calc(var(--lr-page-padding) * 0.8);
  padding-bottom: calc(var(--lr-page-padding) * 1.7);
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      /* Safari only override */
      font-size: 8.22vw;
      @include for-small-tablet-below {
        font-size: 7.8vw;
      }
    }
  }
  @include for-small-tablet-below {
    display: block;
    font-size: 7.86vw;
    margin-left: 0;
    margin-right: 0;
  }
}

.article-card {
  position: relative;
  &__image-container {
    position: relative;
    width: 32vw;
    margin: 0 auto;
    z-index: 1;
    display: block;
    @include for-small-tablet-below {
      width: 100vw;
    }
  }
  .image-wrap {
    width: 32vw;
    @include for-small-tablet-below {
      width: 100vw;
    }
  }
  img {
    aspect-ratio: 1 / 1;
    object-fit: cover;
  }
  &__interviewee {
    color: var(--white);
    font-size: var(--extra-large-font-size);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    @include for-small-tablet-below {
      font-size: 24px;
    }
  }
  &__text-container {
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    font-size: var(--extra-huge-font-size);
    text-align: center;
    @include for-small-tablet-below {
      display: none;
    }
  }
  &__cta {
    display: block;
    font-size: var(--extra-tiny-font-size);
    text-align: center;
    margin-top: 1em;
    @include underline;
    @include for-small-tablet-below {
      display: none;
    }
  }
}

.acknowledgement {
  background-color: var(--green);
  display: flex;
  justify-content: end;
  padding-top: 20vw;
  padding-left: var(--lr-page-padding);
  padding-right: var(--lr-page-padding);
  @include for-small-tablet-below {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
  &__wrapper {
    background-color: var(--black);
    width: 22vw;
    padding: 20vw 2vw var(--lr-page-padding) calc(var(--lr-page-padding) * 0.8);
    @include for-small-tablet-below {
      width: 100%;
      padding: 350px 10vw calc(var(--lr-page-padding) * 1.5)
        var(--lr-page-padding);
    }
  }
  &__container {
    color: var(--white);
    font-size: var(--extra-tiny-font-size);
  }
}

.home-footer {
  padding-top: 10vw;
  background-color: var(--green);
  @include for-small-tablet-below {
    padding-top: 310px;
  }
  &__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    @include for-small-tablet-below {
      grid-template-columns: 1fr;
      padding-bottom: 50px;
    }
  }
  &__lupine-logo {
    padding: 3.7vw 0 0.3vw;
    svg {
      width: 100%;
      height: auto;
      path {
        fill: var(--black);
      }
    }
  }
  &__contact-details-container {
    padding-right: var(--lr-page-padding);
    @include for-small-tablet-below {
      padding-right: 0;
    }
  }
  &__contact-details {
    font-size: var(--medium-font-size);
    @include for-small-tablet-below {
      font-size: var(--tiny-font-size);
    }
  }
  &__instagram-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    margin-top: 0.9vw;
    @include for-small-tablet-below {
      gap: var(--lr-page-padding);
      margin-top: 0.6rem;
    }
  }
  &__instagram-button {
    text-align: center;
    font-size: var(--tiny-font-size);
    background-color: var(--black);
    color: var(--white);
    padding: 0.5vw;
    @include for-small-tablet-below {
      font-size: var(--extra-tiny-font-size);
      padding: var(--lr-page-padding);
    }
  }
}

/* Agency
---------------------------------------------- */

.agency-template {
  .manifesto {
    @include for-small-tablet-below {
      padding-bottom: 0;
      padding-top: 99vw;
      margin-bottom: 100px;
    }
  }
  @include for-small-tablet-below {
    position: relative;
  }
  .client-images {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 10;
    @include for-small-tablet-below {
      left: 0;
      top: 0;
      right: initial;
      bottom: initial;
      position: sticky;
    }
    &__container {
      position: relative;
    }
  }
  .client-image {
    position: absolute;
    bottom: 0;
    right: 0;
    display: block;
    @include for-small-tablet-below {
      left: 0;
      top: 0;
      right: initial;
      bottom: initial;
    }
    &__image {
      @include for-small-tablet-below {
        width: 64vw;
      }
    }
  }
  .agency-services {
    @include for-small-tablet-below {
      padding-top: 80px;
      padding-bottom: 60px;
    }
  }
  .clients-title {
    @include for-small-tablet-below {
      padding-bottom: 70px;
    }
  }
  .client-name {
    &:last-of-type {
      @include for-small-tablet-below {
        padding-bottom: calc(var(--lr-page-padding) * 40);
      }
    }
  }
}

.agency-footer {
  @include large-lupine-logo;
  &__lupine-logo {
    margin-bottom: 3.1vw;
    @include for-small-tablet-below {
      margin-bottom: 50px;
    }
  }
}

.agency-banner {
  display: grid;
  grid-template-columns: 5fr 2fr;
  @include for-small-tablet-below {
    grid-template-columns: 1fr;
    padding-top: var(--lr-page-padding);
    padding-bottom: var(--lr-page-padding);
  }
  &__right {
    padding-top: var(--lr-page-padding);
    text-align: right;
    font-size: var(--extra-tiny-font-size);
    @include for-small-tablet-below {
      text-align: left;
    }
  }
}

.agency-title {
  font-size: 10.59vw;
  margin-left: -0.3vw;
  @include for-small-tablet-below {
    font-size: 17.5vw;
    margin-left: -0.1vw;
  }
}

.client-images {
  z-index: 10;
  position: fixed;
  bottom: 0;
  right: 0;
  will-change: opacity, visibility;
  &__container {
    position: relative;
  }
}

.client-image {
  position: absolute;
  bottom: 0;
  right: 0;
  display: block;
  padding: var(--lr-page-padding);
  opacity: 0;
  &:not(:first-of-type) {
    pointer-events: none;
  }
  &--placeholder {
    opacity: 1;
  }
  &__container {
    background: var(--black);
    color: var(--white);
  }
  &__text {
    font-size: var(--extra-tiny-font-size);
    padding: calc(var(--lr-page-padding) * 0.6);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include for-small-tablet-below {
      width: 100%;
    }
  }
  &__image {
    width: 15vw;
    height: auto;
    aspect-ratio: 1 / 1.25;
    object-fit: cover;
  }
}

.clients-title {
  margin-top: calc(var(--lr-page-padding) * 10);
  position: sticky;
  top: 0;
  font-size: var(--ginormous-font-size);
  @include for-small-tablet-below {
    position: initial;
  }
  &__text {
    text-align: right;
    margin-left: auto;
    margin-right: -0.6vw;
    @include for-small-tablet-below {
      margin-right: -6px;
    }
  }
  &__read-more {
    font-size: var(--extra-tiny-font-size);
    text-align: right;
    margin-top: calc(var(--lr-page-padding) * 1.5);
    margin-left: auto;
    & a {
      @include underline;
    }
  }
}

.client-list {
  margin-top: calc(var(--lr-page-padding) * 15);
  font-size: var(--huge-font-size);
  @include for-small-tablet-below {
    font-size: var(--tiny-font-size);
    text-align: center;
  }
}

.client-name {
  padding-bottom: calc(var(--lr-page-padding) * 2);
  &:last-of-type {
    padding-bottom: calc(var(--lr-page-padding) * 30);
  }
}

/* Journal
---------------------------------------------- */

.journal-banner {
  @include large-lupine-logo;
  &__lupine-logo {
    margin-bottom: -0.8vw;
  }
}

/* Article
---------------------------------------------- */
.article-template {
  .journal-banner {
    display: none;
    @include for-small-tablet-below {
      display: block;
      margin-bottom: calc(var(--lr-page-padding) * 4);
    }
  }
}
.article-navbar {
  $self: &;
  position: fixed;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  font-size: var(--medium-font-size);
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(40px);
  z-index: 10;
  @include for-small-tablet-below {
    display: none;
  }
  &.header-responsive-color {
    #{ $self }__button {
      transition: color 0.5s ease-out;
      color: var(--black);
    }
    &--light {
      #{ $self }__button {
        color: var(--white);
      }
    }
  }
  &__button-wrapper--center {
    text-align: center;
  }
  &__button-wrapper--right {
    text-align: right;
  }
  &__button {
    display: inline-block;
    padding: var(--lr-page-padding);
  }
}

.article-main {
  padding-top: 4vw;
  @include for-small-tablet-below {
    padding-top: var(--lr-page-padding);
  }
}

.article-title {
  font-size: 11vw;
  text-align: center;
}

.article-intro {
  margin-top: 2vw;
  margin-bottom: 5vw;
  font-size: var(--extra-huge-font-size);
  text-align: center;
  @include for-small-tablet-below {
    margin-top: calc(var(--lr-page-padding) * 2);
    margin-bottom: calc(var(--lr-page-padding) * 5);
  }
}

/* Studio
---------------------------------------------- */

.studio-template {
  .fixed-bottom-nav {
    &__container {
      background: rgba(241, 90, 34, 0.07);
    }
    &__link {
      color: var(--white);
    }
  }
}

.index {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: var(--lr-page-padding);
  width: 50vw;
  z-index: 10;
  &__container {
    display: block;
    width: 100%;
    background: rgba(255, 255, 255, 0.03);
    backdrop-filter: blur(17.5px);
  }
  &__list {
    opacity: 0;
    display: none;
    flex-direction: column;
    align-items: center;
    gap: calc(var(--lr-page-padding) * 3);
    padding: calc(var(--lr-page-padding) * 3) 0;
    max-height: calc(100vh - 4vw);
    overflow: scroll;
    @include for-small-tablet-below {
      max-height: 500px;
    }
  }
  &__button {
    display: block;
    position: relative;
    width: 100%;
    color: var(--white);
    font-size: var(--tiny-font-size);
    padding: calc(var(--lr-page-padding) * 0.7) var(--lr-page-padding);
  }
}

.index-event-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--white);
  text-align: center;
  gap: calc(var(--lr-page-padding) * 1);
  @include for-small-tablet-below {
    padding: calc(var(--lr-page-padding) * 1) var(--lr-page-padding);
  }
  &__title {
    font-size: var(--extra-massive-font-size);
    @include for-small-tablet-below {
      font-size: var(--massive-font-size);
    }
  }
  &__artist {
    font-size: var(--large-font-size);
    @include for-small-tablet-below {
      font-size: var(--extra-tiny-font-size);
    }
  }
}

/* Event
---------------------------------------------- */

.event-back {
  position: fixed;
  right: 0;
  font-size: var(--medium-font-size);
  color: var(--white);
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(40px);
  z-index: 10;
  &__button {
    display: inline-block;
    padding: var(--lr-page-padding);
  }
}

.event-header {
  position: fixed;
  z-index: 10;
  width: 45vw;
  color: var(--white);
  transition: top 1s;
  @include for-small-tablet-below {
    padding: var(--lr-page-padding);
    width: 100%;
  }
  &__container {
    background: rgba(255, 255, 255, 0.03);
    backdrop-filter: blur(40px);
  }
  &__title {
    font-size: var(--huge-font-size);
    text-align: center;
    padding: 2.5vw 3vw 1vw;
    // word-break: break-all;
    // hyphens: manual;
    @include for-small-tablet-below {
      font-size: var(--extra-huge-font-size);
      padding: calc(var(--lr-page-padding) * 2) calc(var(--lr-page-padding) * 1)
        calc(var(--lr-page-padding) * 1);
    }
  }
  &__name {
    font-size: var(--large-font-size);
    text-align: center;
    padding-bottom: 1.5vw;
    @include for-small-tablet-below {
      padding-bottom: calc(var(--lr-page-padding) * 1);
      font-size: var(--extra-tiny-font-size);
    }
  }
  &__description {
    padding: 1.5vw 2.7vw;
    text-align: center;
    font-size: var(--extra-small-font-size);
    @include for-small-tablet-below {
      padding: calc(var(--lr-page-padding) * 2) calc(var(--lr-page-padding) * 1);
      font-size: var(--extra-tiny-font-size);
    }
  }
  &__footer {
    position: sticky;
    top: 0;
    font-size: var(--medium-font-size);
    text-align: center;
    display: flex;
    justify-content: center;
    @include for-small-tablet-below {
      font-size: var(--extra-tiny-font-size);
    }
  }
  &__button {
    width: 50%;
    @include for-small-tablet-below {
      padding: calc(var(--lr-page-padding) * 1) 0
        calc(var(--lr-page-padding) * 2);
    }
  }
  &__enquire-button {
    display: inline-block;
    padding: var(--lr-page-padding);
    @include for-small-tablet-below {
      padding: calc(var(--lr-page-padding) * 1) 0
        calc(var(--lr-page-padding) * 2);
    }
  }
}

.event-footer {
  @include studio-title;
  &__studio-title {
    display: inline-block;
    line-height: 100%;
  }
}

.event-main {
  .video-hero {
    pointer-events: none;
    position: relative;
    cursor: pointer;
    .video-hero__wrapper {
      width: 100%;
      background-color: var(--black);
      video {
        object-fit: cover;
        width: 100%;
        height: 100vh;
        display: block;
        background-color: var(--cream);
        @include for-small-tablet-below {
          height: 35vh;
          height: 35svh;
        }
      }
    }
  }
}

/* Contact
---------------------------------------------- */

.contact-template {
  background-color: var(--orange);
  min-height: 100vh;
  .fixed-bottom-nav {
    &__container {
      background: rgba(241, 90, 34, 0.07);
    }
    &__link {
      color: var(--pink);
    }
  }
  .mobile-menu {
    &__container {
      background: rgba(241, 90, 34, 0.07);
    }
    &__link {
      color: var(--pink);
    }
  }
  .mobile-menu-button {
    &__button {
      color: var(--pink);
    }
  }
}

.contact-banner {
  &__lupine-logo {
    display: block;
    padding: var(--lr-page-padding) 0 0;
    margin-bottom: -0.8vw;
    svg {
      width: 100%;
      height: auto;
      path {
        fill: var(--pink);
      }
    }
  }
}

.contact-contents {
  &__container {
    display: grid;
    grid-template-columns: 1.21fr 1fr;
    gap: var(--lr-page-padding);
    color: var(--pink);
    margin-top: calc(var(--lr-page-padding) * 1.4);
    align-content: start;
    @include for-small-tablet-below {
      grid-template-columns: 1fr;
      margin-top: calc(var(--lr-page-padding) * 1.4);
    }
  }
  &__left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  &__right {
    @include for-small-tablet-below {
      margin-top: calc(var(--lr-page-padding) * 4);
      display: flex;
      flex-direction: column-reverse;
    }
  }
  .manifesto {
    padding-top: 0;
    padding-right: 10vw;
    height: initial;
  }
  .contact-details {
    font-size: var(--extra-large-font-size);
    line-height: 111.667%;
    margin-bottom: calc(var(--lr-page-padding) * 1.2);
    @include for-small-tablet-below {
      font-size: var(--tiny-font-size);
      margin-bottom: calc(var(--lr-page-padding) * 2);
    }
  }
}

.instagram-squares {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--lr-page-padding);
  width: 100%;
  margin-bottom: calc(var(--lr-page-padding) * 1.2);
  @include for-small-tablet-below {
    margin-bottom: calc(var(--lr-page-padding) * 6);
  }
  &__individual {
    text-align: center;
    font-size: var(--tiny-font-size);
    color: var(--orange);
    aspect-ratio: 1 / 1;
    position: relative;
  }
  &__text-wrapper {
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--pink);
    transition: background-color 0.5s ease-in, color 0.5s ease-in;
    @media (hover: hover) {
      &:hover {
        background-color: transparent;
        color: var(--white) !important;
      }
    }
  }
  &__text {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 5vw;
    line-height: 105%;
    @include for-small-tablet-below {
      max-width: initial;
    }
  }
  &__image {
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
#mc_embed_signup {
  margin-bottom: 4vw;
  h2 {
    margin-top: calc(var(--lr-page-padding) * 4);
    margin-bottom: calc(var(--lr-page-padding) * 1);
  }
  .formfield__input {
    background-color: var(--pink);
    font-size: 13.5px;
    padding: 9px;
    border: 1px solid var(--pink);
    height: 36px;
    border-radius: 0px;
  }
  .formblock__submit {
    margin-top: 0;
  }
  .formblock__submit input {
    padding: 9px;
    outline: none;
    font-family: var(--body-font);
    font-weight: 700;
    line-height: initial;
    border-color: var(--pink);
    color: var(--pink);
    font-size: 13.5px;
    box-sizing: border-box;
    height: 36px;
    border-radius: 0px;
  }
  .formblock__submit > input:hover {
    background-color: var(--pink);
    color: var(--orange);
  }
  .response {
    margin: 0 0 0em 0 !important;
    padding: 9px 0px !important;
    z-index: 1;
    font-size: var(--extra-tiny-font-size) !important;
  }
}

#mc_embed_signup input.mce_inline_error {
  border: 1px solid var(--pink) !important;
}

#mc_embed_signup div.mce_inline_error {
  margin: 0 0 0em 0 !important;
  padding: 9px 0px !important;
  background-color: transparent !important;
  font-weight: initial !important;
  z-index: 1;
  font-size: var(--extra-tiny-font-size) !important;
  color: var(--pink) !important;
  max-width: 180px;
}

.mc-flex {
  display: flex;
  gap: var(--lr-page-padding);
}

/* Form page
---------------------------------------------- */
.form-page-container {
  background-color: var(--orange);
  color: var(--white);
  .formblock__message__list__item {
    color: var(--white);
  }
  .formfield__checkbox:checked ~ .formfield__checkbox__check::before {
    appearance: initial;
    opacity: 1;
    color: var(--orange);
  }
  .formfield__radio__check,
  .formfield__checkbox__check {
    border: none;
    background-color: var(--white);
  }
  &--studio {
    background-color: var(--green);
    color: var(--black);
    .form-page-banner {
      font-size: 8.1vw;
      padding-top: 0.2vw;
      padding-bottom: 0.2vw;
      margin-left: -0.3vw;
      margin-right: -0.3vw;
      @include for-small-tablet-below {
        font-size: 7.9vw;
        margin-left: -0.3vw;
        margin-right: -0.3vw;
        padding-top: var(--lr-page-padding);
        padding-bottom: var(--lr-page-padding);
      }
    }
    .formblock__message__list__item {
      color: var(--black);
    }
    .formfield__checkbox:checked ~ .formfield__checkbox__check::before {
      color: var(--green);
    }
    .formfield__radio__check,
    .formfield__checkbox__check {
      background-color: var(--black);
    }
  }
  &--transparent {
    background-color: initial;
    color: var(--white);
    .formblock__message__list__item {
      color: var(--white);
    }
    .formfield__checkbox:checked ~ .formfield__checkbox__check::before {
      color: var(--black);
    }
    .formfield__checkbox__check {
      background-color: var(--white);
    }
  }
}

.form-page-background-image {
  position: fixed;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
}

.form-page-banner {
  font-size: 8.5vw;
  padding-top: 0.2vw;
  padding-bottom: 0.2vw;
  margin-left: -0.3vw;
  margin-right: -0.3vw;
  @include for-small-tablet-below {
    font-size: 8.3vw;
    margin-left: -0.3vw;
    margin-right: -0.3vw;
    padding-top: var(--lr-page-padding);
    padding-bottom: var(--lr-page-padding);
  }
}

.form-page-main {
  margin-top: var(--lr-page-padding);
}

.form-page-contents {
  &__container {
    display: flex;
    justify-content: space-between;
    @include for-small-tablet-below {
      flex-direction: column;
    }
  }
  &__left {
    width: 33vw;
    padding-bottom: 4rem;
    @include for-small-tablet-below {
      width: 100%;
      padding-bottom: 0;
    }
  }
  &__right {
    width: 29.1vw;
    @include for-small-tablet-below {
      width: 100%;
      margin-top: calc(var(--lr-page-padding) * 3);
    }
  }
}

.form-page-text {
  font-size: var(--tiny-font-size);
  line-height: 111.5%;
  @include for-small-tablet-below {
    font-size: var(--extra-tiny-font-size);
  }
}

.form-title {
  font-size: var(--massive-font-size);
  @include for-small-tablet-below {
    font-size: var(--tiny-font-size);
  }
}

.form-page-footer {
  @include large-lupine-logo;
  svg path {
    fill: var(--white);
  }
  &__lupine-logo {
    padding-bottom: 3.1vw;
    @include for-small-tablet-below {
      padding-bottom: 50px;
    }
  }
}

/* Form-block-suite plugin */
.formblock {
  margin-top: 4rem;
  gap: 0.5rem;
  font-size: var(--tiny-font-size);
  max-width: initial;
  @include for-small-tablet-below {
    font-size: var(--extra-tiny-font-size);
    margin-top: 1rem;
  }
}

label.formblock_field__label {
  position: absolute !important;
  overflow: hidden;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap: normal !important;
  font-family: var(--serif-font);
}

legend.formblock_field__label {
  font-family: var(--heading-font);
  font-weight: 400;
  line-height: 81.818%;
  text-transform: uppercase;
  font-size: var(--medium-font-size);
  @include for-small-tablet-below {
    font-size: var(--tiny-font-size);
  }
}

.formfield__input,
.formfield__select,
.formfield__radio,
.formfield__textarea {
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding: 9px;
  // border: 1px solid #ebebeb;
  outline: none;
  font-family: var(--body-font);
  font-weight: 700;
  line-height: 111.49%;
  color: var(--orange);
  border: none;
  &::placeholder {
    color: var(--orange);
  }
}

.formfield__container fieldset {
  margin-top: 0.5rem;
}

.formfield__option__label {
  font-family: var(--body-font);
  font-weight: 700;
  line-height: 111.49%;
  margin: 0.5rem 0 0.2rem;
}

.formblock__submit {
  margin-top: 0.5rem;
  width: 100%;
  text-align: initial;
}

.formblock__submit > input,
.formfield__file::file-selector-button {
  display: inline-block;
  border: 1px solid var(--white);
  background: initial;
  line-height: 40px;
  cursor: pointer;
  font-size: var(--tiny-font-size);
  font-family: var(--body-font);
  font-weight: 700;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  @include for-small-tablet-below {
    font-size: var(--extra-tiny-font-size);
  }
}

.formfield__file::file-selector-button {
  line-height: 25px;
}

.formblock__submit > input:hover,
.formfield__file::file-selector-button:hover {
  filter: initial;
  background-color: var(--white);
  color: var(--orange);
}

.form-page-container--studio {
  .form-page-footer {
    svg path {
      fill: var(--black);
    }
  }
  .formfield__input:focus,
  .formfield__select:focus,
  .formfield__textarea:focus {
    border: none;
  }
  .formfield__input,
  .formfield__select,
  .formfield__radio,
  .formfield__textarea {
    background-color: var(--black);
    // border: 1px solid #ebebeb;
    color: var(--green);
    &::placeholder {
      color: var(--green);
    }
  }
  .formblock__submit > input,
  .formfield__file::file-selector-button {
    border: 1px solid var(--black);
    color: var(--black);
  }
  .formblock__submit > input:hover,
  .formfield__file::file-selector-button:hover {
    background-color: var(--black);
    color: var(--green);
  }
}

.form-page-container--transparent {
  .form-page-footer {
    svg path {
      fill: var(--white);
    }
  }
  .formfield__input:focus,
  .formfield__select:focus,
  .formfield__textarea:focus {
    border: none;
  }
  .formfield__input,
  .formfield__select,
  .formfield__radio,
  .formfield__textarea {
    background-color: var(--white);
    // border: 1px solid #ebebeb;
    color: var(--black);
    &::placeholder {
      color: var(--black);
    }
  }
  .formblock__submit > input,
  .formfield__file::file-selector-button {
    border: 1px solid var(--white);
    color: var(--white);
  }
  .formblock__submit > input:hover,
  .formfield__file::file-selector-button:hover {
    background-color: var(--white);
    color: var(--black);
  }
}

.formblock__message--error[data-form="form_error"] {
  background-color: var(--black);
  font-family: var(--body);
  font-weight: 700;
  font-size: var(--extra-tiny-font-size);
}
.formblock__message__list__item {
  font-size: var(--extra-tiny-font-size);
  font-family: var(--body);
  font-weight: 700;
}
.formblock__message--error .formblock__message__text {
  display: none;
}
.formfield__container[data-valid="true"] .formfield__input,
.formfield__container[data-valid="true"] .formfield__select,
.formfield__container[data-valid="true"] .formfield__radio,
.formfield__container[data-valid="true"] .formfield__file,
.formfield__container[data-valid="true"] .formblock__option__container,
.formfield__container[data-valid="true"] .formfield__textarea {
  border: none;
}

.formfield__container[data-valid="false"] .formfield__input,
.formfield__container[data-valid="false"] .formfield__select,
.formfield__container[data-valid="false"] .formfield__radio,
.formfield__container[data-valid="false"] .formfield__file,
.formfield__container[data-valid="false"] .formblock__option__container,
.formfield__container[data-valid="false"] .formfield__textarea {
  border: none;
}

.formfield__radio__check:before,
.formfield__checkbox__check:before {
  appearance: none;
  opacity: 0;
  border: 1px solid var(--white);
}

.formfield__container[data-valid] .formblock__option__container {
  padding: 0px;
}

.formfield__radio:checked ~ .formfield__radio__check,
.formfield__radio:hover ~ .formfield__radio__check,
.formfield__checkbox:checked ~ .formfield__checkbox__check,
.formfield__checkbox:hover ~ .formfield__checkbox__check {
  border: none;
}

.formfield__radio__check,
.formfield__checkbox__check {
  border: none;
  background-color: var(--white);
}

.formfield__radio__check:before,
.formfield__checkbox__check:before {
  border: none;
}

.formblock__message--success[data-form="form_success"] {
  font-size: var(--extra-tiny-font-size);
  font-family: var(--body);
  font-weight: 700;
}

.formblock__submit__bar {
  position: absolute;
  top: 0;
  bottom: 0;
  display: block;
  background-color: var(--orange);
  mix-blend-mode: overlay;
}

/* Blocks
---------------------------------------------- */

.block-type-interviewtext {
  margin: 5vw 0;
  $self: &;
  padding: 0 calc(var(--lr-page-padding) * 4);
  @include for-small-tablet-below {
    grid-template-columns: 1fr;
    padding: 0 var(--lr-page-padding);
  }
  &__container {
    display: grid;
    grid-template-columns: 1.005fr 1.8fr;
    gap: calc(var(--lr-page-padding) * 7);
    @include for-small-tablet-below {
      grid-template-columns: 1fr;
      gap: calc(var(--lr-page-padding) * 4);
    }
    &--stacked {
      grid-template-columns: 1fr;
      gap: calc(var(--lr-page-padding) * 3);
      #{ $self }__item:not(:last-of-type) {
        margin-bottom: 2vw;
      }
      #{ $self }__item:nth-of-type(odd) {
        padding-right: 20vw;
        @include for-small-tablet-below {
          padding-right: 0vw;
        }
      }
      #{ $self }__item:nth-of-type(even) {
        padding-left: 20vw;
        text-align: right;
        @include for-small-tablet-below {
          padding-left: 0vw;
          text-align: initial;
        }
      }
      #{ $self }__item:nth-of-type(even) #{ $self }__name {
        text-align: right;
        @include for-small-tablet-below {
          text-align: right;
        }
      }
      #{ $self }__item:nth-of-type(odd) > div {
        position: initial;
      }
      #{ $self }__name {
        text-align: left;
        @include for-small-tablet-below {
          text-align: right;
        }
      }
    }
  }
  &__item:nth-of-type(odd) > div {
    position: sticky;
    top: 5vw;
  }
  &__name {
    font-size: var(--extra-tiny-font-size);
    margin-bottom: calc(var(--lr-page-padding) * 1);
    text-align: center;
    @include for-small-tablet-below {
      font-size: 12px;
      text-align: right;
    }
  }
  &__paragraphs {
    font-size: var(--large-font-size);
    @include for-small-tablet-below {
      font-size: 20px;
    }
  }
}

.block-type-splitscreen {
  $self: &;
  &__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    @include for-small-tablet-below {
      grid-template-columns: 1fr;
    }
  }
  img {
    object-fit: cover;
    height: 100%;
  }
}

.block-type-customvideo {
  &.video-hero {
    position: relative;
    .video-hero__wrapper {
      width: 100%;
      background-color: var(--black);
      video {
        object-fit: cover;
        width: 100%;
        height: 100vh;
        display: block;
        background-color: var(--cream);
        @include for-small-tablet-below {
          height: 35vh;
          height: 35svh;
        }
      }
    }
  }
}
