@mixin studio-title {
  &__studio-title {
    font-size: 10.59vw;
    margin-left: -.75vw;
    margin-right: -.75vw;
    @include for-small-tablet-below {
      font-size: 10.265vw;
      margin-left: -.5vw;
      margin-right: -.5vw;
    }
  }
}

@mixin large-lupine-logo {
  &__lupine-logo {
    display: block;
    padding: var(--lr-page-padding) 0 0;
    svg {
      width: 100%;
      height: auto;
      path {
        fill: var(--black);
      }
    }
  }
}

@mixin underline {
  text-decoration: underline;
  text-underline-offset: .15em;
}