/* Variables
---------------------------------------------- */
:root {
  --vh: 1;

  --font-size: 20px;
  --body-font: Arial, "Helvetica Neue", Helvetica, sans-serif;
  --heading-font: "Adieu", Arial, "Helvetica Neue", Helvetica, sans-serif;

  --extra-tiny-font-size: 0.95vw; // 14px
  --tiny-font-size: 1.15vw; // 18px
  --extra-small-font-size: 1.2vw; // 20px
  --small-font-size: 1.3vw; // 22px
  --medium-font-size: 1.6vw; // 24px
  --large-font-size: 1.8vw; // 27px
  --extra-large-font-size: 2vw; // 30px
  --huge-font-size: 2.9vw; // 39px
  --extra-huge-font-size: 3.4vw; // 50px
  --massive-font-size: 3.7vw; // 55px
  --extra-massive-font-size: 4.2vw; // 61px
  --ginormous-font-size: 8vw; // 120px

  --regular-font-size: var(--font-size);

  --black: black;
  --white: white;
  --orange: #f15a22;
  --pink: #eabdd8;
  --green: #e5eadc;

  --lr-page-padding: 0.8vw;

  /* Variables for form */
  --formblock-color: var(--orange);
  --formblock-color-invert: #fff;
  --formblock-color-error: #cc0000;
  --formblock-color-success: var(--black);
}

/* Variables for-small-tablet-below
---------------------------------------------- */
:root {
  @include for-small-tablet-below {
    --lr-page-padding: 2vw;
    --extra-tiny-font-size: 14px; // 14px Desktop // 14px Mobile
    --tiny-font-size: 18px; // 18px Desktop // 18px Mobile
    --extra-small-font-size: 1.2vw; // 20px Desktop // 16px Mobile
    --small-font-size: 1.3vw; // 22px
    --medium-font-size: 1.6vw; // 24px
    --large-font-size: 1.8vw; // 27px
    --extra-large-font-size: 2vw; // 30px
    --huge-font-size: 2.9vw; // 39px
    --extra-huge-font-size: 25px; // 50px Desktop // 25px Mobile
    --massive-font-size: 30px; // 55px Desktop // 30px Mobile
    --extra-massive-font-size: 4.2vw; // 61px
    --ginormous-font-size: 66px; // 120px Desktop // 66px Mobile
  }
}
